import React from 'react';
import PropTypes from 'prop-types';
import { Paper, withStyles } from '@material-ui/core';
import classNames from 'classnames';
import Link from '@material-ui/core/Link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import GetIcon from '../utils/getIcon';

const styles = (theme) => ({
    link: {
        '&:hover': {
            textDecoration: `none`
        }
    },
    wrapper: {
        fontSize: `20px`,
        display: `flex`,
        alignItems: `center`,
        justifyContent: `space-between`,
        padding: `15px`,
        margin: `20px 0`,
        backgroundColor: theme.palette.primary.main,
        border: `1px solid #000`
    },
    main: {
        display: `flex`,
        alignItems: `center`
    },
    typeIcon: {
        fontSize: `28px`,
        marginRight: `12px`,
        color: `#FFF`
    },
    text: {
        color: `#FFF`,
        fontWeight: 500
    },
    linkIcon: {
        marginLeft: `6px`,
        fontSize: `14px`,
        color: `#FFF`
    }
});

class QuestLink extends React.Component {
    render() {
        const { classes, text, urlPath, icon } = this.props;
        return (
            <Link
                href={urlPath}
                underline={`none`}
                color={`secondary`}
                className={classes.link}
                target={`_blank`}
            >
                <Paper className={classes.wrapper}>
                    <div className={classes.main}>
                        <FontAwesomeIcon
                            icon={GetIcon(icon)}
                            className={classNames(
                                classes.typeIcon,
                                `text-shadow-double`
                            )}
                        />
                        <span
                            className={classNames(classes.text, `montserrat`)}
                        >
                            {text}
                        </span>
                    </div>
                    <FontAwesomeIcon
                        icon={GetIcon(`external-link-alt`)}
                        className={classNames(
                            classes.linkIcon,
                            `text-shadow-double`
                        )}
                    />
                </Paper>
            </Link>
        );
    }
}

QuestLink.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    text: PropTypes.string.isRequired,
    urlPath: PropTypes.string.isRequired
};

export default withStyles(styles, { withTheme: true })(QuestLink);
