import React from 'react';
import { Link, RichText } from 'prismic-reactjs';
import Embed from '../components/embed';
import Image from '../components/image';
import QuestLink from '../components/quest-link';
import Message from '../components/message';
import Quote from '../components/quote';
import StepHeading from '../components/step-heading';
import TableBlock from '../components/table-block';
import FormatTableData from './formatTableData';
import LinkResolver from './linkResolver';
import HtmlSerializer from './htmlSerializer';
import Slugify from '../utils/slugify';
import Fetch from 'react-fetch-component';
import Lowlight from 'react-lowlight';
import bash from 'highlight.js/lib/languages/bash';
import css from 'highlight.js/lib/languages/css';
import js from 'highlight.js/lib/languages/javascript';
import json from 'highlight.js/lib/languages/json';
import less from 'highlight.js/lib/languages/less';
import php from 'highlight.js/lib/languages/php';
import sql from 'highlight.js/lib/languages/sql';
import xml from 'highlight.js/lib/languages/xml';

Lowlight.registerLanguage('bash', bash);
Lowlight.registerLanguage('css', css);
Lowlight.registerLanguage('js', js);
Lowlight.registerLanguage('json', json);
Lowlight.registerLanguage('less', less);
Lowlight.registerLanguage('php', php);
Lowlight.registerLanguage('sql', sql);
Lowlight.registerLanguage('xml', xml);

const subset = [`bash`, `css`, `js`, `json`, `less`, `php`, `sql`, `xml`];

const getHeading = (heading) => {
    switch (heading.type) {
        case `heading1`:
            return <h1 id={Slugify(heading.text)}>{heading.text}</h1>;
        case `heading2`:
            return <h2 id={Slugify(heading.text)}>{heading.text}</h2>;
        case `heading3`:
            return <h3 id={Slugify(heading.text)}>{heading.text}</h3>;
        case `heading4`:
            return <h4 id={Slugify(heading.text)}>{heading.text}</h4>;
        case `heading5`:
            return <h5 id={Slugify(heading.text)}>{heading.text}</h5>;
        case `heading6`:
            return <h6 id={Slugify(heading.text)}>{heading.text}</h6>;
        default: //do nothing
    }
};

const PrismicSliceContent = function (slice, steps = false) {
    let content = [];
    let count = 1;

    slice.forEach(function (section) {
        if (section.primary) {
            switch (section.type) {
                case 'code_block':
                    if (section.primary.embed_link) {
                        content.push(
                            <Fetch url={section.primary.embed_link} as={`text`}>
                                {({ data }) =>
                                    data && (
                                        <Lowlight
                                            language={section.primary.language}
                                            value={data}
                                            subset={subset}
                                        />
                                    )
                                }
                            </Fetch>
                        );
                    } else if (section.primary.code) {
                        content.push(
                            <Lowlight
                                language={section.primary.language}
                                value={RichText.asText(section.primary.code)}
                                subset={subset}
                            />
                        );
                    }
                    break;

                case 'embed_block':
                    if (section.primary.embed) {
                        content.push(
                            <Embed content={section.primary.embed.html} />
                        );
                    }
                    break;

                case 'h1_heading':
                    content.push(getHeading(section.primary.heading[0]));
                    break;

                case 'heading':
                    if (steps === true) {
                        content.push(
                            <StepHeading
                                heading={RichText.asText(
                                    section.primary.heading
                                )}
                                number={count++}
                            />
                        );
                    } else {
                        content.push(getHeading(section.primary.heading[0]));
                    }
                    break;

                case 'image_block':
                    if (section.primary.image) {
                        let imageTitle = section.primary.image_title
                            ? RichText.asText(section.primary.image_title)
                            : ``;
                        let imageCaption = section.primary.caption
                            ? RichText.asText(section.primary.caption)
                            : ``;
                        content.push(
                            <Image
                                src={section.primary.image.url}
                                mobileSrc={section.primary.image.mobile.url}
                                alt={imageTitle}
                                title={imageTitle}
                                caption={imageCaption}
                            />
                        );
                    }
                    break;

                case 'link':
                    if (section.primary.link) {
                        content.push(
                            <QuestLink
                                urlPath={Link.url(
                                    section.primary.link,
                                    LinkResolver
                                )}
                                text={section.primary.title}
                                icon={section.primary.link_type.toLowerCase()}
                            />
                        );
                    }
                    break;

                case 'message_block':
                    if (section.primary.message_content) {
                        content.push(
                            <Message
                                type={section.primary.message_type.toLowerCase()}
                                title={section.primary.message_title}
                            >
                                {RichText.render(
                                    section.primary.message_content,
                                    LinkResolver,
                                    HtmlSerializer
                                )}
                            </Message>
                        );
                    }
                    break;

                case 'paragraph_block':
                    content.push(
                        <>
                            {RichText.render(
                                section.primary.text,
                                LinkResolver,
                                HtmlSerializer
                            )}
                        </>
                    );
                    break;

                case 'quote_block':
                    if (section.primary.quote) {
                        content.push(
                            <Quote
                                text={RichText.render(
                                    section.primary.quote,
                                    LinkResolver,
                                    HtmlSerializer
                                )}
                                author={
                                    section.primary.author
                                        ? RichText.asText(
                                              section.primary.author
                                          )
                                        : null
                                }
                                link={
                                    section.primary.link
                                        ? section.primary.link.url
                                        : null
                                }
                            />
                        );
                    }
                    break;

                case 'table':
                    if (section.primary.heading_1 && section.fields) {
                        const tableData = FormatTableData(
                            section.primary,
                            section.fields
                        );

                        if (tableData.headings && tableData.rows) {
                            content.push(
                                <TableBlock
                                    headings={tableData.headings}
                                    rows={tableData.rows}
                                />
                            );
                        }
                    }
                    break;

                default: //output nothing
            }
        }
    });
    return content.map((block, index) => <div key={index}>{block}</div>);
};

export default PrismicSliceContent;
