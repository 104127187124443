import slugify from 'slugify';

const Slugify = (input, spaceReplacer = `-`) => {
    if (typeof input === `string`) {
        return slugify(input, {
            replacement: spaceReplacer,
            lower: true
        });
    }
    return input;
};

export default Slugify;
