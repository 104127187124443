import React from 'react';
import Lowlight from 'react-lowlight';

const regex = /`(.*?)`/g;
const reactStringReplace = require('react-string-replace');
let count = 0;

const InsertInlineCodeElements = (string) => {
    if (typeof string === `string`) {
        return reactStringReplace(string, regex, (match, i) => (
            <Lowlight key={count++} value={match} inline={true} />
        ));
    }
};

export default InsertInlineCodeElements;
