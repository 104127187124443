import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import Link from '@material-ui/core/Link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import GetIcon from '../utils/getIcon';

const styles = (theme) => ({
    wrapper: {
        margin: `30px 0 10px`,
        borderRadius: 4,
        backgroundColor: theme.palette.secondary.main,
        color: `#FFF`,
        border: `1px solid #000`
    },
    link: {
        color: `inherit`,
        '&:hover': {
            textDecoration: `none`
        }
    },
    icon: {
        marginRight: `7px`,
        position: `relative`,
        top: `2px`
    },
    title: {
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4,
        padding: 10,
        backgroundColor: theme.palette.primary.main,
        color: `inherit`,
        fontSize: `20px`,
        lineHeight: `24px`,
        display: `flex`,
        alignItems: `top`,
        borderBottom: `1px solid #000`,
        margin: 0,
        fontWeight: 400
    },
    titleText: {
        textTransform: `none`
    },
    name: {
        fontWeight: 600,
        color: theme.palette.secondary.main
    },
    text: {
        fontWeight: 400,
        padding: `10px`,
        '& p': {
            margin: 5
        }
    }
});

class ReadNext extends React.Component {
    render() {
        const { classes, name, url, text } = this.props;
        return (
            <div className={classes.wrapper}>
                <Link href={url} className={classes.link}>
                    <h3 className={classes.title}>
                        <FontAwesomeIcon
                            icon={GetIcon(`book`)}
                            className={classes.icon}
                        />
                        <span className={classes.titleText}>
                            Read Next:{` `}
                            <span className={classes.name}>{name}</span>
                        </span>
                    </h3>
                    <div className={classes.text}>{text}</div>
                </Link>
            </div>
        );
    }
}

ReadNext.propTypes = {
    name: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired
};

export default withStyles(styles, { withTheme: true })(ReadNext);
