import { RichText } from 'prismic-reactjs';
import LinkResolver from './linkResolver';
import HtmlSerializer from './htmlSerializer';

const FormatTableData = (headingData, rowData) => {
    const headings = [
        headingData.heading_1,
        headingData.heading_2,
        headingData.heading_3,
        headingData.heading_4,
        headingData.heading_5,
        headingData.heading_6
    ];

    let tableData = {
        headings: [],
        rows: []
    };

    tableData.headings = headings
        .filter((heading) => heading !== null)
        .map((heading) => heading);

    tableData.rows = rowData.map((row) =>
        Object.keys(row)
            .filter((key) => row[key] !== null)
            .map((key) =>
                RichText.render(row[key], LinkResolver, HtmlSerializer)
            )
    );

    return tableData;
};

export default FormatTableData;
