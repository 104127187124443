import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core';

const styles = (theme) => ({
    imageBlock: {
        margin: `24px 0`,
        borderRadius: `4px`,
        textAlign: `center`
    },
    image: {
        maxWidth: `100%`,
        borderTopLeftRadius: `4px`,
        borderTopRightRadius: `4px`,
        display: `block`
    },
    imageOnly: {
        borderBottomLeftRadius: `4px`,
        borderBottomRightRadius: `4px`,
        margin: 0
    },
    content: {
        marginTop: `-5px`,
        backgroundColor: '#F5F5F5',
        borderTop: `none`,
        padding: `24px`,
        borderBottomLeftRadius: `4px`,
        borderBottomRightRadius: `4px`
    },
    title: {
        fontWeight: 600,
        paddingBottom: 0,
        borderBottom: `1px solid ${theme.palette.primary.main}`,
        marginBottom: `24px`
    },
    caption: {
        fontSize: `18px`
    }
});

class Image extends React.Component {
    getImage = () => {
        if (this.props.mobileSrc) {
            return this.props.mobileSrc;
        }
        return this.props.src;
    };

    getAltImage = () => {
        if (this.props.mobileSrc) {
            return this.props.src;
        }
    };

    render() {
        const { classes, alt, title, caption, noMargin } = this.props;

        return (
            <div
                className={classNames(classes.imageBlock, {
                    [classes.imageOnly]: noMargin
                })}
            >
                <picture>
                    {this.getAltImage() ? (
                        <source
                            srcSet={this.getAltImage()}
                            media="(min-width: 768px)"
                        />
                    ) : (
                        ``
                    )}
                    <img
                        className={classNames(classes.image, {
                            [classes.imageOnly]: !title
                        })}
                        src={this.getImage()}
                        alt={alt}
                    />
                </picture>
                {title && (
                    <div className={classes.content}>
                        <h6 className={classes.title}>{title}</h6>
                        {caption && (
                            <span className={classes.caption}>{caption}</span>
                        )}
                    </div>
                )}
            </div>
        );
    }
}

Image.propTypes = {
    src: PropTypes.string.isRequired,
    mobileSrc: PropTypes.string,
    alt: PropTypes.string,
    title: PropTypes.string,
    caption: PropTypes.string,
    noMargin: PropTypes.bool
};

export default withStyles(styles, { withTheme: true })(Image);
