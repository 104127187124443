import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import classNames from 'classnames';
import Avatar from '@material-ui/core/Avatar';

const styles = (theme) => ({
    wrapper: {
        margin: `30px 0`,
        display: `flex`,
        alignItems: `center`
    },
    avatar: {
        fontFamily: `Londrina Solid`,
        backgroundColor: `transparent`,
        color: theme.palette.primary.main,
        position: `relative`,
        left: -6,
        top: 6,
        fontSize: `40px`,
        width: `36px`,
        height: `36px`,
        [theme.breakpoints.up('sm')]: {
            left: -7,
            top: 7,
            width: `42px`,
            height: `42px`
        }
    },
    text: {
        display: `block`,
        width: `100%`,
        position: `relative`,
        top: 4,
        fontSize: `21px`,
        fontWeight: 600,
        paddingBottom: `2px`,
        margin: `0 0 0 -5px`,
        [theme.breakpoints.up('sm')]: {
            fontSize: `24px`
        }
    }
});

class StepHeading extends React.Component {
    render() {
        const { classes, heading, number } = this.props;
        return (
            <div className={classes.wrapper}>
                <Avatar className={classNames(classes.avatar, `text-shadow`)}>
                    {number}
                </Avatar>
                <h2 className={classes.text}>{heading}</h2>
            </div>
        );
    }
}

StepHeading.propTypes = {
    heading: PropTypes.node.isRequired,
    number: PropTypes.node.isRequired
};

export default withStyles(styles, { withTheme: true })(StepHeading);
