import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Link from '@material-ui/core/Link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import GetIcon from '../utils/getIcon';
import QuestMeta from '../components/quest-meta';
import classNames from 'classnames';

const styles = (theme) => ({
    quest: {
        width: `100%`,
        marginBottom: `30px`,
        position: `relative`,
        backgroundColor: `#F3F3F3`,
        border: `1px solid #000`
    },
    link: {
        color: `#FFF`,
        '&:hover': {
            textDecoration: `none`
        }
    },
    header: {
        color: `#FFF`,
        borderTopLeftRadius: `4px`,
        borderTopRightRadius: `4px`,
        display: `flex`,
        justifyContent: `space-between`,
        alignItems: `top`,
        padding: `1em`,
        backgroundColor: theme.palette.secondary.main,
        borderBottom: `1px solid #000`
    },
    title: {
        fontSize: `20px`,
        [theme.breakpoints.up(`sm`)]: {
            fontSize: `18px`
        },
        [theme.breakpoints.up(`md`)]: {
            fontSize: `20px`
        },
        fontWeight: 700,
        margin: 0,
        padding: 0,
        maxWidth: `90%`,
        overflowWrap: `break-word`,
        hyphens: `auto`
    },
    id: {
        fontFamily: `Londrina Solid`,
        fontWeight: 400,
        fontSize: 20,
        display: `none`
    },
    icon: {
        position: `relative`,
        top: 4,
        fontSize: `18px`,
        margin: 0
    },
    description: {
        color: theme.palette.secondary.main,
        padding: `20px`,
        fontWeight: 400,
        fontSize: `17.5px`,
        lineHeight: `20px`,
        height: `125px`,
        overflow: `hidden`,
        marginBottom: `65px`
    },
    tags: {
        padding: `0 0 20px 20px`,
        '&:last-child': {
            paddingBottom: `20px`
        },
        position: `absolute`,
        bottom: 0,
        width: `100%`
    },
    tag: {
        color: `#FFF`,
        backgroundColor: theme.palette.primary.main,
        fontSize: `14px`,
        cursor: `pointer`
    },
    comingSoon: {
        color: theme.palette.secondary.main,
        opacity: 0.75,
        cursor: `not-allowed`
    },
    comingSoonText: {
        fontSize: `18px`,
        fontWeight: 600,
        textTransform: `uppercase`
    }
});

class QuestCard extends React.Component {
    render() {
        const {
            classes,
            name,
            url,
            id,
            description,
            icon,
            backgroundColour,
            difficulty,
            magentoVersions,
            date,
            link
        } = this.props;

        if (name) {
            return (
                <Card className={classes.quest}>
                    {link && (
                        <Link href={url} className={classes.link}>
                            <header
                                className={classes.header}
                                style={{ background: backgroundColour }}
                            >
                                <h4
                                    color={`inherit`}
                                    className={classNames(
                                        classes.title,
                                        `text-shadow`
                                    )}
                                >
                                    <span className={classes.id}>{id}.</span>
                                    {` `}
                                    {name}
                                </h4>
                                <FontAwesomeIcon
                                    icon={GetIcon(icon)}
                                    className={classNames(
                                        classes.icon,
                                        `text-shadow-double`
                                    )}
                                />
                            </header>
                            <CardContent className={classes.description}>
                                {description
                                    ? description
                                    : `Click here to learn more about ${name}`}
                            </CardContent>
                            <CardContent className={classes.tags}>
                                <QuestMeta
                                    date={date}
                                    magentoVersions={magentoVersions}
                                    difficulty={difficulty}
                                    paddingRight={`10px`}
                                />
                            </CardContent>
                        </Link>
                    )}
                    {!link && (
                        <div className={classes.comingSoon}>
                            <header className={classes.header}>
                                <h4
                                    color={`inherit`}
                                    className={classNames(
                                        classes.title,
                                        `text-shadow`
                                    )}
                                >
                                    <span className={classes.id}>{id}.</span>
                                    {` `}
                                    {name}
                                </h4>
                                <FontAwesomeIcon
                                    icon={GetIcon(icon)}
                                    className={classNames(
                                        classes.icon,
                                        `text-shadow-double`
                                    )}
                                />
                            </header>
                            <CardContent className={classes.description}>
                                <div className={classes.comingSoonText}>
                                    Coming Soon!
                                </div>
                            </CardContent>
                        </div>
                    )}
                </Card>
            );
        }
        return null;
    }
}

QuestCard.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    name: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    id: PropTypes.number,
    icon: PropTypes.string,
    description: PropTypes.string,
    difficulty: PropTypes.string,
    date: PropTypes.string,
    magentoVersions: PropTypes.array
};

export default withStyles(styles, { withTheme: true })(QuestCard);
