import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import Tooltip from '@material-ui/core/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import GetIcon from '../utils/getIcon';

const styles = (theme) => ({
    wrapper: {
        width: `100%`,
        display: `flex`,
        overflowX: `scroll`
    },
    item: {
        marginRight: `10px`,
        fontSize: `14px`,
        lineHeight: `16px`,
        backgroundColor: theme.palette.secondary.main,
        color: `#FFF`,
        padding: `2px`,
        border: `1px solid #000`,
        fontWeight: 600
    },
    apprentice: {
        backgroundColor: `saddlebrown`
    },
    warrior: {
        backgroundColor: `silver`
    },
    master: {
        backgroundColor: `goldenrod`
    },
    magentoVersions: {
        backgroundColor: theme.palette.primary.main
    },
    icon: {
        marginRight: `8px`,
        fontSize: `16px`,
        minWidth: `.9em`,
        maxWidth: `.9em`
    }
});

class QuestMeta extends React.Component {
    getIcon = (difficulty) => {
        switch (difficulty) {
            case `warrior`:
                return `axe-battle`;
            case `master`:
                return `wand-magic`;
            default:
                return `fist-raised`;
        }
    };

    getLowerCase = (text) => {
        if (text) {
            return text.toLowerCase();
        }
        return text;
    };

    getDate = (date) => {
        let dateObject = new Date(Date.parse(date.replace(`+0000`, ``)));
        let dateFormat = { year: `numeric`, month: `long`, day: `numeric` };
        return dateObject.toLocaleDateString(`en-GB`, dateFormat);
    };

    getMagentoVersions = (magento_versions) => {
        if (magento_versions && typeof magento_versions === `object`) {
            return magento_versions
                .map((magento_version) => magento_version.magento_version)
                .join(` / `);
        }
    };

    render() {
        const {
            classes,
            date,
            magentoVersions,
            difficulty,
            paddingRight
        } = this.props;
        const versions = this.getMagentoVersions(magentoVersions);
        const difficultyLowerCase = this.getLowerCase(difficulty);
        return (
            <div className={classes.wrapper}>
                {difficulty && (
                    <Tooltip
                        title={`Difficulty`}
                        disableFocusListener
                        disableTouchListener
                    >
                        <Chip
                            label={
                                <>
                                    <FontAwesomeIcon
                                        icon={this.getIcon(difficultyLowerCase)}
                                        className={classNames(
                                            classes.icon,
                                            `text-shadow`
                                        )}
                                    />
                                    {difficulty}
                                </>
                            }
                            className={classNames(
                                classes.item,
                                classes[difficultyLowerCase],
                                `text-shadow`
                            )}
                        />
                    </Tooltip>
                )}
                <Tooltip
                    title={`Last updated`}
                    disableFocusListener
                    disableTouchListener
                >
                    <Chip
                        label={
                            <>
                                <FontAwesomeIcon
                                    icon={`calendar-check`}
                                    className={classNames(
                                        classes.icon,
                                        `text-shadow`
                                    )}
                                />
                                {this.getDate(date)}
                            </>
                        }
                        className={classNames(
                            classes.item,
                            classes.date,
                            `text-shadow`
                        )}
                    />
                </Tooltip>
                {versions && (
                    <Tooltip
                        title={`Version compatibility`}
                        disableFocusListener
                        disableTouchListener
                    >
                        <Chip
                            label={
                                <>
                                    <FontAwesomeIcon
                                        icon={GetIcon(`magento`)}
                                        className={classNames(
                                            classes.icon,
                                            `text-shadow-double`
                                        )}
                                    />
                                    {versions}
                                </>
                            }
                            className={classNames(
                                classes.item,
                                classes.magentoVersions,
                                `text-shadow`
                            )}
                        />
                    </Tooltip>
                )}
                <div style={{ width: paddingRight, minWidth: paddingRight }} />
            </div>
        );
    }
}

QuestMeta.propTypes = {
    date: PropTypes.string.isRequired,
    difficulty: PropTypes.string,
    magentoVersions: PropTypes.array,
    paddingRight: PropTypes.string
};

export default withStyles(styles, { withTheme: true })(QuestMeta);
