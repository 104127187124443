import React from 'react';
import { Elements } from 'prismic-reactjs';
import Lowlight from 'react-lowlight';
import InsertInlineCodeElements from './insertInlineCodeElements';

const propsWithUniqueKey = (props, key) => {
    return Object.assign(props || {}, { key });
};

const insertInlineCodeElements = (elements, key) => {
    elements.forEach(function (child) {
        if (child !== null && child[0]) {
            child[0] = InsertInlineCodeElements(child[0]);
        }
    });
    return elements;
};

const getPreformattedCodeBlock = (element, key) => {
    if (
        element !== null &&
        element[0] !== null &&
        typeof element[0] === `object`
    ) {
        const linesOfCode = element[0].filter(
            (item) => typeof item === `string`
        );
        if (
            element[0][0].startsWith('bin/magento') ||
            element[0][0].startsWith('$ ') ||
            element[0][0].startsWith('> ')
        ) {
            return (
                <Lowlight
                    language={`bash`}
                    key={key}
                    value={linesOfCode.join(`\n`)}
                    inline={false}
                />
            );
        }
        return (
            <Lowlight key={key} value={linesOfCode.join(`\n`)} inline={false} />
        );
    }
    return element;
};

const HtmlSerializer = (type, element, content, children, key) => {
    let props = {};

    switch (type) {
        case Elements.paragraph:
            let quoteBlock = false;
            if (
                children[0] !== null &&
                children[0][0] !== null &&
                typeof children[0][0] === `string` &&
                children[0][0].startsWith(`> `)
            ) {
                children[0][0] = children[0][0].replace(`> `, ``);
                quoteBlock = true;
            }
            return React.createElement(
                quoteBlock ? `blockquote` : `p`,
                propsWithUniqueKey(props, key),
                insertInlineCodeElements(children)
            );

        case Elements.listItem:
            return React.createElement(
                'li',
                propsWithUniqueKey(props, key),
                <span>{insertInlineCodeElements(children)}</span>
            );

        case Elements.oListItem:
            return React.createElement(
                'li',
                propsWithUniqueKey(props, key),
                <span>{insertInlineCodeElements(children)}</span>
            );

        case Elements.preformatted:
            return getPreformattedCodeBlock(children, key);

        default:
            return null;
    }
};

export default HtmlSerializer;
