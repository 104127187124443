import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const styles = (theme) => ({
    wrapper: {
        backgroundColor: `rgb(245, 245, 245)`,
        borderRadius: 4,
        border: `1px solid #000`,
        overflowX: `scroll`,
        maxHeight: 500,
        [theme.breakpoints.up(`sm`)]: {
            maxHeight: 400
        }
    },
    table: {},
    header: {},
    headerCell: {
        backgroundColor: theme.palette.primary.main,
        color: `#FFF`,
        textTransform: `uppercase`,
        fontWeight: 700,
        fontSize: `18px`
    },
    body: {},
    row: {},
    cell: {
        borderBottom: `1px solid rgb(225, 225, 225)`,
        borderRight: `1px solid rgb(225, 225, 225)`,
        '&:last-child': {
            borderRight: 0
        }
    }
});

class TableBlock extends React.Component {
    render() {
        const { classes, tableName, headings, rows } = this.props;

        return (
            <div className={classes.wrapper}>
                <Table
                    stickyHeader
                    className={classes.table}
                    aria-label={tableName}
                >
                    <TableHead>
                        <TableRow className={classes.header}>
                            {headings.map((heading, index) => (
                                <TableCell
                                    key={index}
                                    className={classNames(
                                        classes.cell,
                                        classes.headerCell,
                                        `text-shadow`
                                    )}
                                >
                                    {heading}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody className={classes.body}>
                        {rows.map((row, index) => (
                            <TableRow key={index} className={classes.row}>
                                {row.map((cell, index) => (
                                    <TableCell
                                        key={index}
                                        className={classes.cell}
                                    >
                                        {cell}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </div>
        );
    }
}

TableBlock.propTypes = {
    tableName: PropTypes.string,
    headings: PropTypes.array.isRequired,
    rows: PropTypes.array.isRequired
};

export default withStyles(styles, { withTheme: true })(TableBlock);
