import React from 'react';
import { graphql } from 'gatsby';
import { RichText } from 'prismic-reactjs';
import withRoot from '../withRoot';
import { Paper, withStyles } from '@material-ui/core';
import Layout from '../components/layout';
import classNames from 'classnames';
import magequestLogo from '../images/logo.png';
import SEO from '../components/seo';
import scrollToElement from 'scroll-to-element';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import GetIcon from '../utils/getIcon';
import Page from '../components/page';
import Menu from '../components/menu';
import Search from '../components/search';
import backgroundPattern from '../images/random-grey-variations.png';

const orangeBorderTopHeight = 5;
const orangeBorder = `${orangeBorderTopHeight}px solid `;

const styles = (theme) => ({
    wrapper: {
        borderTop: orangeBorder + theme.palette.primary.main,
        backgroundColor: theme.palette.secondary.main,
        background: `url(${backgroundPattern})`,
        width: `100%`,
        color: `#FFF`,
        height: `100vh`,
        display: `flex`,
        flexDirection: `column`,
        justifyContent: `top`,
        position: `relative`
    },
    toolbar: {
        position: `absolute`,
        display: `flex`,
        justifyContent: `space-between`,
        width: `100%`,
        padding: `6px 5% 0`,
        [theme.breakpoints.up(`sm`)]: {
            padding: `6px 6% 0`
        },
        [theme.breakpoints.up(`lg`)]: {
            padding: `6px 7.5% 0`
        },
        [theme.breakpoints.up(`xl`)]: {
            padding: `6px 11% 0`
        }
    },
    logo: {
        height: `37vh`,
        display: `block`,
        margin: `3vh auto 0`
    },
    title: {
        textAlign: `center`,
        margin: `3vh 0 0`,
        fontSize: `14vw`,
        lineHeight: `14vw`,
        [theme.breakpoints.up('sm')]: {
            fontSize: `14vh`,
            lineHeight: `14vh`
        },
        color: theme.palette.primary.main,
        textTransform: `uppercase`,
        width: `100%`,
        maxWidth: `100%`
    },
    titleAlt: {
        marginLeft: `10px`,
        color: `#FFF`
    },
    strapline: {
        textAlign: `center`,
        borderTop: `1.5px solid #000`,
        borderBottom: `1.5px solid #000`,
        backgroundColor: theme.palette.primary.main,
        color: `#FFF`,
        margin: `4vh 0 0`,
        width: `100%`,
        fontSize: `5vh`,
        lineHeight: `6vh`,
        padding: `2px 10%`,
        letterSpacing: `2px`,
        [theme.breakpoints.up('sm')]: {
            textShadow: `-2px 0 #000, 0 2px #000, 2px 0 #000, 0 -2px #000`,
            padding: `2px 0`,
            fontSize: `8vh`,
            lineHeight: `9.5vh`
        }
    },
    cta: {
        textTransform: `uppercase`,
        fontWeight: 600,
        width: `100%`,
        textAlign: `center`,
        margin: `3vh 0 0`,
        fontSize: `3vh`,
        bottom: `14vh`,
        [theme.breakpoints.up('sm')]: {
            bottom: `3vh`,
            fontSize: `3.5vh`
        },
        position: `absolute`,
        cursor: `pointer`
    },
    arrow: {
        fontSize: `3.5vh`,
        [theme.breakpoints.up('sm')]: {
            fontSize: `4vh`
        },
        margin: `10px auto 0`,
        width: `100%`,
        textAlign: `center`,
        minWidth: '1em',
        maxWidth: '1em'
    },
    mainContent: {
        minHeight: `100vh`,
        padding: `2%`,
        '& p, & li': {
            fontSize: `20px`
        }
    }
});

class IndexPage extends React.Component {
    getDescription = (page) => {
        if (page.description) {
            return RichText.asText(page.description);
        }
        return RichText.asText(page.name);
    };

    scrollDown = () => {
        scrollToElement(`#getStarted`, {
            duration: 500
        });
    };

    render() {
        const { data, classes } = this.props;
        const page = data.prismic.page;
        return (
            <Layout noHeader={true}>
                <div className={classes.wrapper}>
                    <div className={classes.toolbar}>
                        <Menu lightText={true} />
                        <Search lightText={true} />
                    </div>
                    <SEO
                        title={`Start your journey today`}
                        description={this.getDescription(page)}
                    />
                    <img
                        className={classes.logo}
                        src={magequestLogo}
                        alt={`MageQuest - Level up your Magento skills`}
                    />
                    <h1
                        className={classNames(
                            classes.title,
                            `londrina`,
                            `text-shadow-double`
                        )}
                    >
                        Mage<span className={classes.titleAlt}>Quest</span>
                    </h1>
                    <h2
                        className={classNames(
                            classes.strapline,
                            `londrina`,
                            `text-shadow`
                        )}
                    >
                        Level up your Magento skills
                    </h2>
                    <div
                        className={classNames(
                            classes.cta,
                            `text-shadow`,
                            `montserrat`
                        )}
                        onClick={this.scrollDown}
                    >
                        Start your journey today
                        <div
                            className={classNames(classes.arrow, `text-shadow`)}
                        >
                            <FontAwesomeIcon icon={GetIcon(`chevron-down`)} />
                        </div>
                    </div>
                </div>
                <Paper className={classes.mainContent} id={`getStarted`}>
                    <Page>{page}</Page>
                </Paper>
            </Layout>
        );
    }
}

export default withRoot(withStyles(styles, { withTheme: true })(IndexPage));

export const pageQuery = graphql`
    query Homepage {
        prismic {
            page(uid: "index", lang: "en-gb") {
                name
                description
                _meta {
                    uid
                }
                body {
                    __typename
                    ... on PRISMIC_PageBodyH1_heading {
                        type
                        primary {
                            heading
                        }
                    }
                    __typename
                    ... on PRISMIC_PageBodyHeading {
                        type
                        primary {
                            heading
                        }
                    }
                    ... on PRISMIC_PageBodyParagraph_block {
                        type
                        primary {
                            text
                        }
                    }
                    ... on PRISMIC_PageBodyCode_block {
                        type
                        primary {
                            language
                            embed_link
                            code
                        }
                    }
                    ... on PRISMIC_PageBodyMessage_block {
                        type
                        primary {
                            message_type
                            message_title
                            message_content
                        }
                    }
                    ... on PRISMIC_PageBodyImage_block {
                        type
                        primary {
                            image
                            image_title
                            caption
                        }
                    }
                    ... on PRISMIC_PageBodyQuote_block {
                        type
                        primary {
                            quote
                            author
                            link {
                                ... on PRISMIC__ExternalLink {
                                    url
                                }
                            }
                        }
                    }
                    ... on PRISMIC_PageBodyEmbed_block {
                        type
                        primary {
                            embed
                        }
                    }
                }
                featured_realms {
                    realm {
                        __typename
                        ... on PRISMIC_Realm {
                            name
                            icon
                            coming_soon
                            _meta {
                                uid
                            }
                        }
                    }
                }
                featured_quests {
                    quest {
                        __typename
                        ... on PRISMIC_Quest {
                            name
                            description
                            icon
                            difficulty
                            coming_soon
                            magento_versions {
                                magento_version
                            }
                            _meta {
                                uid
                                lastPublicationDate
                            }
                        }
                    }
                }
            }
        }
    }
`;
