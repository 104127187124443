import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';

const styles = (theme) => ({
    wrapper: {
        margin: `15px 0`,
        textAlign: `center`
    },
    content: {
        '& iframe': {
            width: `100%`,
            height: `auto`,
            [theme.breakpoints.up('sm')]: {
                height: `-webkit-fill-available`
            }
        }
    }
});

class Embed extends React.Component {
    render() {
        const { classes, content } = this.props;
        return (
            <div className={classes.wrapper}>
                <div
                    className={classes.content}
                    dangerouslySetInnerHTML={{ __html: content }}
                />
            </div>
        );
    }
}

Embed.propTypes = {
    content: PropTypes.node.isRequired
};

export default withStyles(styles, { withTheme: true })(Embed);
