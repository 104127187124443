import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';

const styles = (theme) => ({
    author: {
        fontWeight: 600,
        textTransform: 'uppercase',
        textAlign: 'center',
        fontSize: '1.2em',
        paddingBottom: '1.5em'
    },
    span: {
        fontWeight: 700,
        color: theme.palette.primary.main,
        padding: '0 .5em'
    }
});

class Author extends React.Component {
    render() {
        const { classes, name } = this.props;
        return (
            <div className={classes.author}>
                <span className={classes.span}>–</span> {name}
                {` `}
                <span className={classes.span}>–</span>
            </div>
        );
    }
}

Author.propTypes = {
    name: PropTypes.node.isRequired
};

export default withStyles(styles, { withTheme: true })(Author);
