import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core';
import { RichText } from 'prismic-reactjs';
import PrismicSliceContent from '../utils/prismicSliceContent';
import LinkResolver from '../utils/linkResolver';
import HtmlSerializer from '../utils/htmlSerializer';
import ContactForm from '../components/contact-form';
import ReadNext from '../components/read-next';
import RealmCard from '../components/realm-card';
import QuestCard from '../components/quest-card';

const styles = (theme) => ({
    content: {
        padding: `5px 20px 30px`,
        [theme.breakpoints.up(`sm`)]: {
            padding: `5px 30px 30px`
        },
        color: '#000',
        '& > *:first-child': {
            marginTop: 0
        },
        '& > div:first-child > *:first-child': {
            marginTop: 0
        },
        '& > div:first-child > div:first-child > *:first-child': {
            marginTop: 0
        },
        '& > div:first-child > div:first-child > div:first-child > *:first-child': {
            marginTop: 0
        }
    },
    index: {
        paddingTop: 20,
        [theme.breakpoints.up(`sm`)]: {
            paddingTop: 30
        }
    },
    cardsContainer: {},
    cardsInner: {},
    cardsTitle: {},
    cards: {
        display: `flex`,
        flexWrap: `wrap`,
        margin: `30px 20px`,
        [theme.breakpoints.up(`sm`)]: {
            margin: `10px`
        },
        '& > div': {
            [theme.breakpoints.up(`sm`)]: {
                flexGrow: 1,
                flex: `1 1 45%`,
                margin: `10px`
            },
            [theme.breakpoints.up(`md`)]: {
                flexGrow: 1,
                flex: `1 1 30%`,
                margin: `15px`
            }
        }
    },
    flexPlaceholderQuest: {
        margin: `0 !important`
    },
    flexPlaceholderRealm: {}
});

const backgroundColours = [
    `#059A92`,
    `#B38755`,
    `#E2583E`,
    `#FDC821`,
    `#9CD2AE`
];
let count = 0;

class Page extends React.Component {
    getPageBody = (page) => {
        if (page.body) {
            if (page._meta.uid === `contact`) {
                return (
                    <>
                        {PrismicSliceContent(page.body)}
                        <ContactForm />
                    </>
                );
            }
            return PrismicSliceContent(page.body);
        }
        return ``;
    };

    getRealms = (realms) => {
        if (realms && realms.length > 0 && realms[0].realm !== null) {
            return realms
                .filter(
                    (node) => node.realm && node.realm.coming_soon !== `Yes`
                )
                .map((node) => (
                    <RealmCard
                        name={RichText.asText(node.realm.name)}
                        backgroundColour={this.cycleBackgroundColour()}
                        icon={node.realm.icon}
                        url={`/${node.realm._meta.uid}`}
                        link={true}
                        key={node.realm._meta.uid}
                    />
                ));
        }
        return null;
    };

    getQuests = (quests) => {
        if (quests && quests.length > 0 && quests[0].realm !== null) {
            return quests
                .filter(
                    (node) => node.quest && node.quest.coming_soon !== `Yes`
                )
                .map((node) => (
                    <QuestCard
                        name={RichText.asText(node.quest.name)}
                        url={`/${node.quest._meta.uid}`}
                        id={count}
                        description={
                            node.quest.description
                                ? RichText.asText(node.quest.description)
                                : ``
                        }
                        icon={node.quest.icon}
                        backgroundColour={this.cycleBackgroundColour()}
                        date={node.quest._meta.lastPublicationDate}
                        difficulty={node.quest.difficulty}
                        magentoVersions={node.quest.magento_versions}
                        link={node.quest.coming_soon !== `Yes`}
                        key={node.quest._meta.uid}
                    />
                ));
        }
        return null;
    };

    cycleBackgroundColour = () => {
        if (count === backgroundColours.length) {
            count = 0;
        }
        return backgroundColours[count++];
    };

    getReadNext = (page) => {
        let readNext = {};

        if (page.read_next && page.read_next.name) {
            readNext.name = RichText.asText(page.read_next.name);
            readNext.uid = page.read_next._meta.uid;
            readNext.text = page.read_next_text
                ? RichText.render(
                      page.read_next_text,
                      LinkResolver,
                      HtmlSerializer
                  )
                : ``;
        }
        return readNext;
    };

    render() {
        const { classes, children } = this.props;
        const page = children;
        const body = this.getPageBody(page);
        const featuredRealms = this.getRealms(page.featured_realms);
        const featuredQuests = this.getQuests(page.featured_quests);
        const readNext = this.getReadNext(page);

        console.log(featuredQuests);

        return (
            <>
                <div
                    className={classNames(
                        `page`,
                        classes.content,
                        classes[page._meta.uid]
                    )}
                >
                    {body}
                    {readNext.name && readNext.uid && (
                        <ReadNext
                            name={readNext.name}
                            url={`/${readNext.uid}`}
                            text={readNext.text}
                        />
                    )}
                    <div className={classes.cardsContainer}>
                        {featuredRealms && featuredRealms.length > 0 && (
                            <div className={classes.cardsInner}>
                                <h2 className={classes.cardsTitle}>
                                    Featured Realms
                                </h2>
                                <div className={classes.cards}>
                                    {featuredRealms}
                                    <RealmCard
                                        name={`View all Realms`}
                                        backgroundColour={`#FC9A00`}
                                        icon={`plus-circle`}
                                        url={`/realms`}
                                        link={true}
                                        key={`view-all`}
                                    />
                                    <div
                                        className={classes.flexPlaceholderRealm}
                                    />
                                    <div
                                        className={classes.flexPlaceholderRealm}
                                    />
                                    <div
                                        className={classes.flexPlaceholderRealm}
                                    />
                                    <div
                                        className={classes.flexPlaceholderRealm}
                                    />
                                </div>
                            </div>
                        )}
                        {featuredQuests && featuredQuests.length > 0 && (
                            <div className={classes.cardsInner}>
                                <h2 className={classes.cardsTitle}>
                                    Featured Quests
                                </h2>
                                <div className={classes.cards}>
                                    {featuredQuests}
                                    <div
                                        className={classes.flexPlaceholderQuest}
                                    />
                                    <div
                                        className={classes.flexPlaceholderQuest}
                                    />
                                    <div
                                        className={classes.flexPlaceholderQuest}
                                    />
                                    <div
                                        className={classes.flexPlaceholderQuest}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </>
        );
    }
}

Page.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    children: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(Page);
