import React from 'react';
import PropTypes from 'prop-types';
import Author from './author';
import { withStyles } from '@material-ui/core';
import Link from '@material-ui/core/Link';
import classNames from 'classnames';

const styles = (theme) => ({
    quoteBlock: {
        backgroundColor: `#F5F5F5`,
        border: `1px solid ${theme.palette.primary.main}`,
        padding: `2em 2em 0`,
        margin: `24px 0`,
        borderRadius: `4px`,
        [theme.breakpoints.up(`sm`)]: {
            padding: `3em 2em 0`
        }
    },
    blockquote: {
        fontSize: `1em`,
        fontWeight: 300,
        textAlign: `center`,
        margin: `0 0 1em 0`,
        padding: 0,
        display: `inline-block`,
        width: `100%`,
        position: `relative`,
        '& p': {
            margin: 0
        },
        '& a': {
            color: `inherit`
        },
        '& a:hover': {
            color: theme.palette.primary.main
        },
        '&::before': {
            width: 0,
            border: 0
        }
    },
    link: {
        color: `inherit`,
        '&:hover': {
            textDecoration: `none`
        }
    },
    text: {
        fontStyle: `italic`,
        margin: `0 5% 20px`,
        fontSize: `18px`,
        fontWeight: 600
    },
    quote: {
        fontFamily: `Londrina Solid`,
        fontStyle: `italic`,
        color: theme.palette.primary.main,
        position: `absolute`,
        fontSize: `4em`,
        [theme.breakpoints.up(`sm`)]: {
            fontSize: `6em`
        }
    },
    quoteLeft: {
        top: `-.4em`,
        left: `-.3em`,
        [theme.breakpoints.up(`sm`)]: {
            left: `-.15em`
        }
    },
    quoteRight: {
        bottom: 0,
        right: `-.15em`,
        [theme.breakpoints.up(`sm`)]: {
            right: 0
        }
    }
});

class Quote extends React.Component {
    render() {
        const { classes, text, author, link } = this.props;
        const blockquote = (
            <blockquote className={classes.blockquote}>
                <div className={classNames(classes.quote, classes.quoteLeft)}>
                    &ldquo;
                </div>
                <div className={classes.text}>{text}</div>
                <div className={classNames(classes.quote, classes.quoteRight)}>
                    &bdquo;
                </div>
            </blockquote>
        );

        return (
            <div className={classes.quoteBlock}>
                {link && (
                    <Link href={link} className={classes.link}>
                        {blockquote}
                        {author && <Author name={this.props.author} />}
                    </Link>
                )}
                {!link && (
                    <>
                        {blockquote}
                        {author && <Author name={this.props.author} />}
                    </>
                )}
            </div>
        );
    }
}

Quote.propTypes = {
    text: PropTypes.node.isRequired
};

export default withStyles(styles, { withTheme: true })(Quote);
