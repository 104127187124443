import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import Link from '@material-ui/core/Link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import GetIcon from '../utils/getIcon';

const styles = (theme) => ({
    realm: {
        backgroundColor: `#F5F5F5`,
        width: `100%`,
        marginBottom: `30px`,
        borderRadius: `4px`,
        display: `flex`,
        alignItems: `center`,
        minHeight: `100px`,
        border: `1px solid #000`
    },
    container: {
        padding: `1em 2em`,
        width: `100%`,
        height: `100%`,
        display: `flex`,
        justifyContent: `space-between`,
        alignItems: `center`,
        color: `#FFF`,
        '&:hover': {
            textDecoration: `none`
        }
    },
    title: {
        fontSize: `22px`,
        lineHeight: `22px`,
        [theme.breakpoints.up(`sm`)]: {
            fontSize: `18px`,
            lineHeight: `18px`
        },
        [theme.breakpoints.up(`md`)]: {
            fontSize: `20px`,
            lineHeight: `20px`
        },
        [theme.breakpoints.up(`lg`)]: {
            fontSize: `23px`,
            lineHeight: `23px`
        },
        fontWeight: 700,
        margin: 0,
        padding: 0,
        maxWidth: `80%`,
        overflowWrap: `break-word`,
        hyphens: `auto`
    },
    icon: {
        fontSize: `30px`,
        [theme.breakpoints.up(`sm`)]: {
            fontSize: `24px`
        },
        [theme.breakpoints.up(`md`)]: {
            fontSize: `26px`
        },
        [theme.breakpoints.up(`lg`)]: {
            fontSize: `30px`
        },
        margin: 0,
        position: `relative`,
        top: -2
    },
    comingSoon: {
        backgroundColor: theme.palette.secondary.light,
        opacity: 0.95,
        cursor: `not-allowed`
    },
    comingSoonText: {
        fontSize: `14px`
    }
});

class RealmCard extends React.Component {
    render() {
        const { classes, name, backgroundColour, icon, url, link } = this.props;

        if (name) {
            return (
                <>
                    {link && (
                        <Card
                            className={classes.realm}
                            style={{ background: backgroundColour }}
                        >
                            <Link href={url} className={classes.container}>
                                <h4
                                    color={`inherit`}
                                    className={classNames(
                                        classes.title,
                                        `text-shadow`
                                    )}
                                >
                                    {name}
                                </h4>
                                <FontAwesomeIcon
                                    icon={GetIcon(icon)}
                                    className={classNames(
                                        classes.icon,
                                        `text-shadow-double`
                                    )}
                                />
                            </Link>
                        </Card>
                    )}
                    {!link && (
                        <Card className={classes.realm}>
                            <div
                                className={classNames(
                                    classes.container,
                                    classes.comingSoon
                                )}
                            >
                                <h4
                                    color={`inherit`}
                                    className={classNames(
                                        classes.title,
                                        `text-shadow`
                                    )}
                                >
                                    {name}
                                    <div className={classes.comingSoonText}>
                                        Coming Soon!
                                    </div>
                                </h4>
                                <FontAwesomeIcon
                                    icon={GetIcon(icon)}
                                    className={classNames(
                                        classes.icon,
                                        `text-shadow-double`
                                    )}
                                />
                            </div>
                        </Card>
                    )}
                </>
            );
        }
        return null;
    }
}

RealmCard.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    name: PropTypes.string.isRequired,
    backgroundColour: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    link: PropTypes.bool.isRequired
};

export default withStyles(styles, { withTheme: true })(RealmCard);
