import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

const styles = (theme) => ({
    form: {
        width: `90%`,
        margin: `20px 5%`
    },
    details: {
        width: `100%`,
        [theme.breakpoints.up(`sm`)]: {
            width: `60%`
        }
    },
    input: {
        width: `90%`,
        marginBottom: 30,
        '& input, & textarea, & label': {
            fontSize: 20
        }
    },
    name: {},
    email: {},
    message: {
        marginTop: 10,
        marginBottom: 50,
        '& textarea': {
            marginBottom: 3
        }
    },
    submitButton: {
        fontWeight: 700
    }
});

class ContactForm extends React.Component {
    render() {
        const { classes } = this.props;

        return (
            <form
                name={`contact`}
                action={`https://getform.io/f/a2a660e3-3387-4ac6-aa13-c7d6b28c2da6`}
                method={`POST`}
                className={classes.form}
            >
                <div className={classes.details}>
                    <TextField
                        name={`name`}
                        label={`Name`}
                        className={classNames(classes.input, classes.name)}
                        autoComplete={`name`}
                        placeholder={`Enter your name...`}
                    />
                    <TextField
                        name={`email`}
                        type={`email`}
                        label={`Email`}
                        autoComplete={`email`}
                        className={classNames(classes.input, classes.email)}
                        placeholder={`Enter your email...`}
                    />
                </div>
                <div>
                    <TextField
                        name={`message`}
                        type={`textarea`}
                        label={`Message`}
                        className={classNames(classes.input, classes.message)}
                        placeholder={`Enter your message...`}
                        multiline
                    />
                </div>
                <Button
                    type={`submit`}
                    size={`large`}
                    variant={`contained`}
                    color={`primary`}
                    className={classes.submitButton}
                >
                    Submit
                </Button>
            </form>
        );
    }
}

ContactForm.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(ContactForm);
